.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 6rem 0rem 2rem 0rem;
    transition: margin-left $transitionDuration;
    margin-left: 240px;
}

.layout-main {
    flex: 1 1 auto;
}
