.item-status {
    &.status-loaded {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-failed {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-processed {
        background: #689F38;
        color: #ffffff;
    }
}
