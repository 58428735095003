/*.layout-main-container {
    position: fixed;
    top:5.5rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 0rem 0rem 0rem 0rem;

    transition: margin-left;
    margin-left: 240px;
}*/
#desktop-app {
    position: fixed;
    top: 106px;
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transition: -webkit-filter .75s;
    transition: filter .75s;
    transition: filter .75s, -webkit-filter .75s
}

#desktop-app.darkmode {
    -webkit-filter: invert(90%);
    filter: invert(90%)
}

#desktop-app.darkmode .no-darkmode {
    transition: -webkit-filter .75s;
    transition: filter .75s;
    transition: filter .75s, -webkit-filter .75s;
    -webkit-filter: invert(100%);
    filter: invert(100%)
}

#desktop-app.darkmode .twitter-icon {
    fill: #000
}

.main-router {
    height: 100%;
    overflow-y: hidden
}

.main-view-navbar {
    position: fixed;
    top: 70px;
    z-index: 4;
    /*height: 55px;
    min-height: 55px;
    max-height: 55px;*/
    width: 100%;
    background: #fff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, .25);
    /*padding: 10px 20px;*/
    transition: margin .5s;
}

.main-view-navbar.folded {
    margin-top: -53px;
}

.main-view-navbar.action-menu {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 -20px;
}

.internal-cont {
    height: 35px;
    justify-content: flex-end;
}

.internal-cont > * {
    /*margin: 0 10px*/
}

.mainview-navbar-navs {
    /*max-width: 150px*/
}

.prior-works {
    /*margin-left: 0*/
}

.secondary-top-navbar-btn {
    max-width: 35px;
    margin-left: 0;
    margin-right: 5px
}


.outline-button {
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 8px;
}

.outline-button.outline_button_active {
    background-color: #005082;
    color: #fff
}

.outline-button.outline_button_active:hover:not(.unhoverable) {
    background-color: #266a95
}

.outline-button.outline_button_inactive {
    background-color: #fff;
    color: #005082
}

.outline-button.outline_button_inactive:hover:not(.unhoverable) {
    background-color: #d9e5ec
}

.outline-button.greyedout {
    background-color: #fff;
    color: #ddd;
    border-color: #ddd;
    cursor: default
}

.outline-button.unhoverable {
    cursor: default
}

.outlined-slot {
    padding: 8px;
    /*padding-bottom: 5.5px;*/
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px)
}

.sponsors-div {
    flex-wrap: nowrap;
    align-content: center
}

.outline-button {
    text-align: center;
    vertical-align: middle;
    /*
    line-height: 90px;
    width: 100%;
    border: 1px solid #80a8c1;
    font-size: 16px;
    */

    cursor: pointer;
    border-right: 1px solid;
    font-weight: bold;
    height: 31px;
}


.height-limited-text {
    display: inline-block;
    overflow: hidden
}


.secondary-outline-button {
    width: 100%;
    border: 1px solid #e6e6e6;
    font-size: 16px;
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #fff;
    --outline-background-color: #fff;
    color: #000;
    height: 35px;
    min-width: 35px;
    border-radius: 17.5px
}

.secondary-outline-button.outline_button_active {
    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    color: #000
}

.secondary-outline-button.outline_button_inactive {
    color: #000
}

.secondary-outline-button.outline_button_inactive[data-v-19919271]:hover:not(.unhoverable) {
    box-shadow: 0 0 4px rgba(0, 0, 0, .25)
}

.secondary-outline-button .outlined-slot {
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    line-height: 1.4
}

.sponsors-div {
    flex-wrap: nowrap;
    align-content: center
}

.autocomplete-searchbar {
    height: 35px;
    border-radius: 17.5px
}


.main-view-window {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-flow: row
}


.minilist-column {
    max-width: 20vw;
    min-width: 19vw;
    z-index: 3;
    transition: margin .5s
}

.minilist-column.folded {
    margin-left: -19vw
}

.minilist-column.conference {
    max-width: 67vw;
    min-width: 67vw;
    transition: none
}

.minilist-column.conference.folded {
    margin-left: -67vw
}

.internal-mainview-column {
    width: 100%;
    z-index: 2
}

.abstract-text {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 7px
}

.abstract-text.mobile-abstract {
    font-size: 18px
}


.abstractbox-column {
    max-width: 25vw;
    min-width: 25vw;
    z-index: 3;
    transition: margin .5s
}

.abstractbox-column.folded {
    margin-right: -25vw
}

/*.abstractbox-column.conference {
    max-width: 33vw;
    min-width: 33vw;
    right: 0;
    position: -webkit-sticky;
    position: sticky
}*/

.paper-title {
    flex-grow: 1;
    font-size: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
    overflow: hidden
}

.paper-title.small_screen_title {
    -webkit-line-clamp: 3;
    font-size: 18px
}

.graph-view {
    position: relative;
    height: 100%;
    width: 100%
}

.buttons-box {
    position: absolute;
    /*margin-top: auto;*/
    margin-left: 30px;
    margin-right: 10px;
    align-items: flex-end
}

.reset_with_tooltip {
    z-index: 2;
    position: relative;
    margin-left: 3px;
}

.reset_with_tooltip > .tooltip-text {
    visibility: hidden;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background-color: #005082;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 6;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    font-size: 12px;
    font-weight: 400;
    line-height: normal
}

.reset_with_tooltip > .tooltip-text:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #005082 transparent transparent transparent
}

.reset_with_tooltip:hover > .tooltip-text {
    visibility: visible;
    transition-delay: 0s
}


.graph-inline-cont {
    z-index: 1
}

.svg-container {
    z-index: 1;

    position: absolute;
    /*
   top: 0;
   left: 0;
    */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;

}

.flex-row {
    display: flex;
    justify-content: space-between
}

.last-button {
    margin-left: auto;
    margin-top: 4px
}

#expand-button {
    margin-right: 0
}

.search-icon {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    flex-shrink: 0
}

.minilist-searchbar {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    width: 100%;
    min-width: 0;
    margin: 5px 0;
    margin-right: 5px
}

.searchbar-row {
    padding: 5px 15px;
    flex: 1;
    color: #707070
}

.searchbar-row .search-icon {
    fill: #707070
}

.searchbar-row:focus-within {
    color: #000
}

.minilist {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%
}

.fold-button-div-left {
    right: -23px
}

.fold-button-div-right {
    left: -23px
}

.items-list-scrollbox {
    flex-grow: 1;
    overflow: hidden
}

.list-group-item-mod {
    cursor: pointer;
    z-index: auto;
    display: flex;
    flex-flow: column;
    background-color: #fff
}

.list-group-item-mod.second {
    background-color: #ebebeb
}

.list-group-item-mod.second {
    background-color: #ebebeb
}

.list-group-item-mod.second.highlight {
    background-color: #c8e4e8
}

.list-group-item-mod.hover h5, .list-group-item-mod.second h5, .list-group-item-mod:hover h5 {
    color: #005082
}

.list-group-item-mod.hover.main h5, .list-group-item-mod.second.main h5, .list-group-item-mod:hover.main h5 {
    color: #7a306c
}

.list-group-item-mod:not(.main) {
    padding-top: 15px
}

.list-group-item-mod.main {
    background-color: #f4eef3
}

.list-group-item-mod.main.highlight {
    background-color: #cfe7ee
}

.list-group-item-mod h5 {
    color: #000;
    line-height: 19px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.origin-paper-title {
    margin-top: 10px;
    margin-bottom: 5px
}

.origin-paper-title span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #7a306c
}

.horizontal-flexbox {
    display: flex;
    flex-flow: row;
    font-size: 100%;
    color: #707070;
    margin-right: 20px;
    margin-left: 20px
}

.authors {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.metadata-item {
    font-size: 14px;
    line-height: 16px
}


.bottom_border_div {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    height: 1px;
    background-color: #ebebeb
}

.items-list:hover .bottom_border_div:not(.ffox), .items-list:hover .horizontal-flexbox:not(.ffox) {
    margin-right: 14px
}

.bottom_border_div_main {
    margin-top: 15px;
    width: 100%;
    height: 1px;
    background-color: #000;
    opacity: .2
}

.minilist-header {
    position: relative;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .07)
}

.minilist-header.expanded {
    box-shadow: none
}

.last-button {
    margin-left: auto;
    margin-top: 4px
}


.minilist-searchbar {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    width: 100%;
    min-width: 0;
    margin: 5px 0;
    margin-right: 5px
}

.minilist-searchbar::-webkit-input-placeholder {
    font-size: 14px;
    color: #000;
    opacity: .3
}

.minilist-searchbar::-moz-placeholder {
    font-size: 14px;
    color: #000;
    opacity: .3
}

.minilist-searchbar:-ms-input-placeholder {
    font-size: 14px;
    color: #000;
    opacity: .3
}

.minilist-searchbar::placeholder {
    font-size: 14px;
    color: #000;
    opacity: .3
}

.minilist-searchbar.conference {
    max-height: 25px;
    margin-top: 20px;
    margin-right: 280px
}

.searchbar-row {
    padding: 5px 15px;
    flex: 1;
    color: #707070
}

.searchbar-row .search-icon {
    fill: #707070
}

.searchbar-row:focus-within {
    color: #000
}

.searchbar-row:focus-within .search-icon {
    fill: #000
}

.searchbar-row:focus-within .minilist-searchbar {
    border-bottom: 1px solid #000
}

.searchbar-row.conference {
    min-height: 70px
}

.minilist {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%
}

.minilist.conference {
    box-shadow: none
}

.items-list-scrollbox {
    flex-grow: 1;
    overflow: hidden
}

.items-list {
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    scrollbar-color: #ccc #fff;
    scrollbar-width: thin
}

.items-list::-webkit-scrollbar {
    width: 6px;
    height: 6px
}

.items-list::-webkit-scrollbar-track {
    background: #fff
}

.items-list::-webkit-scrollbar-thumb {
    background-color: #ccc
}

.items-list:not(:hover):not(.ffox) {
    scrollbar-width: none;
    -ms-overflow-style: none
}

.items-list:not(:hover):not(.ffox)::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent
}

.abstractbox {
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
    max-width: 25vw;
    min-width: 25vw;
    position: relative;
    right: 0
}

.abtract-scrollbox {
    flex: 1;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    /*padding: 20px 20px*/
}

.bottom_border_div {
    margin-top: 13px;
    margin-left: 20px;
    margin-right: 20px;
    height: 1px;
    background-color: #ebebeb
}

.scrollbox {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: #ccc #fff;
    scrollbar-width: thin;
    padding: 20px
}

.title_link {
    margin-bottom: 7px;
    font-weight: 550;
    font-size: 18px;
    color: #000;
    line-height: 21px;
    cursor: pointer
}

@media screen and (max-height: 650px) {
    .title_link {
        font-size: 16px
    }
}

.title_link:hover {
    text-decoration: underline
}


.flex-row {
    flex-direction: row
}

.flex-wrap {
    flex-wrap: wrap
}

.items-start {
    align-items: flex-start
}

.items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.justify-items-start {
    justify-items: start
}

input::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

input::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

input:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

input::placeholder {
    color: #6c757d;
    opacity: 1
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Roboto, sans-serif;
    outline: none !important
}

.shadowed-box {
    box-shadow: 0 4px 12px rgba(0, 0, 0, .19)
}

.flexrow {
    display: flex;
    flex-flow: row
}

.flexrow.wrap {
    flex-flow: row wrap
}

.flexcolumn {
    display: flex;
    flex-flow: column
}

.flexcolumn.wrap {
    flex-flow: column wrap
}

.fold-button-div {
    position: absolute;
    top: 15px;
    color: #a5a5a5;
    z-index: -1;
    box-sizing: border-box;
    background-color: #fff;
    padding: 15px 10px;
    cursor: pointer;
    border: 0 solid #ccc
}

.mini-button {
    margin: 0 25px;
    cursor: pointer;
    color: #005082;
    fill: #005082;
    text-decoration: none;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    padding: 5px;
    border: 1px transparent;
    border-radius: 4px
}

.mini-button .icon {
    display: inline;
    margin-right: 15px
}

.mini-button:hover {
    text-decoration: none
}

.mini-button:hover:not(.unhoverable) {
    background-color: #f5f5f5
}

.leaflet-top {
    top: 30px;
}

.leaflet-bottom {
    bottom: 70px;
}

.leaflet-right {
    right: 30px;
}

.leaflet-left {
    left: 30px;
}

.leaflet-div-icon {
    height: 11px !important;
    width: 11px !important;
    border-radius: 6px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.5rem;
}

.leaflet-disabled {
    display: none
}


.sr-only {
    display: none;
}

.leaflet-draw {
    display: none
}

.margin-right {
    margin-right: 3px;
}

.AccordionTab-without-padding div.p-accordion-content {
    padding: 0;
}


.accordion-custom .p-accordion-tab .p-accordion-header .p-accordion-header-link{
    padding:0rem
}

