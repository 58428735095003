.leaflet-container {
    width: 100%;
    height: 100vh;
}


.tabview-demo .tabview-header-icon i, .tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
    margin: 0 .5rem;
}

.p-tabview-nav {
    font-size: 1.3em !important;
}

.p-tabview-nav li {
    width: 13%;
}



