.leaflet-container {
    width: 100%;
    height: 100vh;
}


.tabview-demo .tabview-header-icon i, .tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
    margin: 0 .5rem;
}

.p-tabview-nav {
    background: red;
}

.tabview-custom {
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}


.leaflet-tooltip.my-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    color: #ffffff;
    font-size: 14px;
}


.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0rem 0.5rem !important;
}

.p-slidemenu {
    width: 80%;
}


